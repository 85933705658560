import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Radio, Space } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../../../lib/Helper';
import SwitchBasic from '../../../../../../components/switch/SwitchBasic';

const schema = {
    tipe: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            loadingContent: true,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                is_used: this.props.data ? (this.props.data.is_used === "1" ? true : false) : false,
                is_active: this.props.data ? (this.props.data.is_active === "1" ? true : false) : false
            },
            loadingContent: false
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });

        const params = {
            tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            tipe: values.tipe ? values.tipe : null,
        }

        let endpoint = "kurikulum/semester"
        let method = "POST"
        if (values.id) {
            params.is_used = values.is_used ? 1 : 0
            params.is_active = values.is_active ? 1 : 0
            endpoint = "kurikulum/semester/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { data } = this.props
        const { values, loadingContent } = this.state

        if (loadingContent) {
            return null
        }

        return (
            <Modal
                maskClosable={false}
                title={"Form Semester"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>``
                            <Form.Item label="Tipe Semester *">
                                <Radio.Group onChange={(e) => {
                                    this.handleChangeSetValue("tipe", e.target.value)
                                }} value={values.tipe}>
                                    <Space direction="vertical">
                                        <Radio value="Ganjil">Ganjil</Radio>
                                        <Radio value="Genap">Genap</Radio>
                                    </Space>
                                </Radio.Group>

                            </Form.Item>
                        </Col>
                        {
                            data.id ? <Col xs={24}>
                                <Form.Item label="Penggunaan *">
                                    <SwitchBasic
                                        value={values.is_used}
                                        defaultChecked={values.is_used}
                                        onChange={(val) => {
                                            this.handleChangeSetValue("is_used", val)
                                        }} /> Aktifkan jika ingin data tersebut aktif untuk digunakan
                                </Form.Item>
                            </Col> : null
                        }
                        {
                            data.id ? <Col xs={24}>
                                <Form.Item label="Status *">
                                    <SwitchBasic
                                        value={values.is_active}
                                        defaultChecked={values.is_active}
                                        onChange={(val) => {
                                            this.handleChangeSetValue("is_active", val)
                                        }} /> Aktifkan jika ingin data tersebut tampil
                                </Form.Item>
                            </Col> : null
                        }
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal} disabled={this.state.loading}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
