import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Modal, DatePicker, Button, Form, Row, Col } from 'antd';
import { dateFormat } from '../../../constant/constant_format';
import { SelectJenisTamu, SelectTujuanTamu } from '../../../components/select';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

class Filter extends React.Component {
    render() {
        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Jenis Tamu">
                                <SelectJenisTamu
                                    defaultValue={this.props.values && this.props.values.jenis_tamu}
                                    onChange={(val) => {
                                        this.props.setTempTableFilter("jenis_tamu", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Tujuan">
                                <SelectTujuanTamu
                                    defaultValue={this.props.values && this.props.values.tujuan}
                                    onChange={(val) => {
                                        this.props.setTempTableFilter("tujuan", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Tanggal *">
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format={dateFormat}
                                    defaultValue={this.props.values && this.props.values.tgl_awal && this.props.values.tgl_akhir ? [moment(this.props.values.tgl_awal, dateFormat), moment(this.props.values.tgl_akhir, dateFormat)] : null}
                                    onChange={val => {

                                        if (val) {
                                            let periodeAwal = moment(val[0]).format(dateFormat)
                                            let periodeAkhir = moment(val[1]).format(dateFormat)

                                            this.props.setTempTableFilter("tgl_awal", periodeAwal)
                                            this.props.setTempTableFilter("tgl_akhir", periodeAkhir)
                                        } else {
                                            this.props.setTempTableFilter("tgl_awal", null)
                                            this.props.setTempTableFilter("tgl_akhir", null)
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
