import React from 'react';
import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Upload } from 'antd';
import TextField from '@mui/material/TextField';
import { InboxOutlined } from '@ant-design/icons';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { _success, _setAxios, _getFile, _beforeUploadPdf20MB, _getYear, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../lib/Helper';
import { SelectJenisBantuanLembaga, SelectSumberBantuanLembaga } from '../../../../components/select/material-ui';

import "./style.css"

const { Dragger } = Upload;
const schema = {
    jenis_bantuan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    sumber: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nominal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        format: {
            pattern: /^[0-9.]+$/,
            message: function () {
                return validate.format("^Nominal harus berupa agka");
            }
        },
    },
    tahun: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }
    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadPdf20MB(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            loading: true
        });
        const params = {
            jenis_bantuan: values.jenis_bantuan ? values.jenis_bantuan : null,
            sumber: values.sumber ? values.sumber : null,
            nominal: values.nominal ? _isNumber(values.nominal) : null,
            keterangan: values.keterangan ? values.keterangan : null,
            tahun: _getYear(values.tahun),
            file: await _getFile(this.state.fileList),
        }

        let endpoint = "lembaga/riwayat-bantuan"
        let method = "POST"
        if (values.id) {
            endpoint = "lembaga/riwayat-bantuan/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form riwayat bantuan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                // width={1024}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24}>
                        <Row gutter={[24, 24]} >
                            <Col xs={24}>
                                <SelectJenisBantuanLembaga
                                    errors={errors}
                                    value={values.jenis_bantuan || ''}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("jenis_bantuan", val ? val.value : null)
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <SelectSumberBantuanLembaga
                                    errors={errors}
                                    value={values.sumber || ''}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("sumber", val ? val.value : null)
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        views={['year']}
                                        label="Tahun *"
                                        value={values.tahun || ""}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tahun", newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            size="small"
                                            error={this.hasError('tahun')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                this.hasError('tahun') ? errors.tahun[0] : null
                                            }
                                        />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={24}>
                                <TextField
                                    fullWidth
                                    label="Nominal *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="nominal"
                                    // type="number"
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nominal", e.target.value)
                                    }}
                                    // value={values.nominal || null}
                                    value={values.nominal ? _fmtRupiah(values.nominal) : null}
                                    error={this.hasError('nominal')}
                                    helperText={
                                        this.hasError('nominal') ? errors.nominal[0] : null
                                    }
                                />
                            </Col>
                            <Col xs={24}>
                                <TextField
                                    multiline
                                    fullWidth
                                    label="Keterangan"
                                    // size="small"
                                    minRows={3}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="keterangan"
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                    value={values.keterangan || ''}
                                    error={this.hasError('keterangan')}
                                    helperText={
                                        this.hasError('keterangan') ? errors.keterangan[0] : null
                                    }
                                />
                            </Col>
                            <Col xs={24}>
                                <Dragger
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    fileList={this.state.fileList}
                                    onChange={this.handleChangeFile}
                                    beforeUpload={this.beforeUploadFile}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: PDF</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 20MB</p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
