import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Select, Input } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../../lib/Helper';
dayjs.extend(customParseFormat);

const { Option } = Select;
const { TextArea } = Input;

const schema = {
    biaya: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    nominal: {
        numericality: {
            message: "tidak valid"
        }
    },
    batas_pembayaran: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
            ddl: {
                jenisPos: [],
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
            },
        })
        this.getJenisPOos()
    }

    getJenisPOos = () => {
        _setAxios("keuangan/pemasukan/jenis-pos/dropdown", "POST").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisPos: resp.data.data
                    },
                })
            }
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        console.log("this.props.data", this.props.data)
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });

        const params = {
            pemasukan_id: this.props.data.pemasukan_id,
            pos_pemasukan_id: values.biaya ? values.biaya : null,
            nominal: values.nominal ? _isNumber(values.nominal) : null,
            batas_pembayaran: values.batas_pembayaran ? values.batas_pembayaran : null,
            keterangan: values.keterangan ? values.keterangan : null,
        }

        let endpoint = "keuangan/pemasukan/detail"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        console.log("this", this.props.data)
        const { ddl } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Detail Pemasukan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Biaya *">
                                <Select
                                    allowClear
                                    name="biaya"
                                    placeholder="Biaya"
                                    defaultValue={this.props.data.pos_pemasukan_nama}
                                    onChange={(e, newValue) => {
                                        console.log("newValue", newValue)
                                        this.handleChangeSetValue("biaya", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {ddl.jenisPos.map((row, i) => {
                                        return <Option key={i} value={row.id}>{row.nama}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Nominal *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="nominal"
                                    placeholder="0"
                                    defaultValue={this.props.data.nominal}
                                    value={this.state.values.nominal ? _fmtRupiah(this.state.values.nominal) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nominal", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Batas Pembayaran *" extra="Data bisa berupa tanggal atau yang lainnya.">
                                <Input
                                    style={{ width: '100%' }}
                                    name="batas_pembayaran"
                                    placeholder="Batas pembayaran"
                                    defaultValue={this.props.data.batas_pembayaran}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("batas_pembayaran", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={this.props.data.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
