import React from 'react';
import { connect } from 'react-redux'
import { Tabs, PageHeader, Breadcrumb } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import StrukturOrganisasi from './list/index';
import DocumentActive from './document-active';
import PreloadContent from '../../../components/preload/PreloadContent';
import AuthRedirect from '../../../components/AuthRedirect';
import { isAuth } from '../../../lib/Helper';

const { TabPane } = Tabs;

class LembagaStrukturOrganisasiIndex extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            tabIdx: "0",
        };
    }

    onChangeTab = (key) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "document-active"
        })
    }

    render() {
        const { loading } = this.state;
        const { privilege } = this.props;

        const access = privilege.access["/lembaga/struktur-organisasi"]
        if (!isAuth(privilege) || access === undefined) {
          return <AuthRedirect />
        }

        const steps = [
            {
                title: 'Dokumen Aktif',
                content: <DocumentActive {...this.props} />,
                key: "document-active",
            }, {
                title: 'Struktur Organisasi',
                content: <StrukturOrganisasi {...this.props} />,
                key: "struktur-organisasi",
            }
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Lembaga</Breadcrumb.Item>
                        <Breadcrumb.Item>Struktur Organisasi</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Struktur Organisasi"
                    />
                    {
                        loading ? <PreloadContent /> : <Tabs defaultActiveKey="document-active" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                            {steps.map((item, i) => (
                                <TabPane key={item.key} tab={item.title}>
                                    {steps[i].content}
                                </TabPane>
                            ))}
                        </Tabs>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LembagaStrukturOrganisasiIndex);
