export const excludeWidgetPath = [
  "/",
  "#lembaga",
  "#configuration",
  "#log-export-import",
  "#notification",
  "#master",
  "#keuangan",
  "#konten",
  "/buku-tamu",
  "#ekstrakulikuler-pramuka",
  "#kurikulum",
  "#sarana-prasarana"
]


export const actionMock =
{
  "create": true,
  "detail": true,
  "update": true,
  "delete": true,
  "view": true
}

export const accessMock = {
  "action": actionMock,
}