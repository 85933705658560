import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _success, _setAxios, _tableLogActivity } from '../../../lib/Helper';
import { kepemilikanOptions } from '../../../data/options';

import { Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, PageHeader, Tabs, Space, Menu, Dropdown, Tag } from 'antd';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';

const { TabPane } = Tabs;

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        length: {
            maximum: 200,
            message: 'Terlalu panjang'
        }
    },
    lahan_id: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    kepemilikan: {
        presence: { allowEmpty: false, message: 'harus diisi' },

    },
    thn_dibangun: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jml_lantai: {
        numericality: {
            message: "harus angka"
        }
    },
    ukuran_panjang: {
        numericality: {
            message: "harus angka"
        }
    },
    ukuran_lebar: {
        numericality: {
            message: "harus angka"
        }
    },
};

class Gedung extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "ASC",
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                visible: false,
                loading: false,
            },
            ddl: {
                lahan: [],
                kepemilikan: [
                    { id: "Milik sendiri", name: "Milik sendiri" },
                    { id: "Bukan Milik sendiri", name: "Bukan Milik sendiri" },
                ],
                jenis_kerusakan: []
            }

        };
    }

    componentDidMount() {
        this.fetchTable(true)
        this.getLahan()
        this.getJenisKerusakan()
    }


    getLahan = () => {
        _setAxios("lahan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        lahan: resp.data.data
                    }
                })
            }
        })
    }
    getJenisKerusakan = () => {
        _setAxios("gedung-jenis-kerusakan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenis_kerusakan: resp.data.data
                    }
                })
            }
        })
    }

    getGedungByID = (id) => {
        _setAxios("gedung/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const { values } = this.state
                let totalValue = 0
                const totalData = this.state.ddl.jenis_kerusakan.length > 0 ? this.state.ddl.jenis_kerusakan.length : 0

                resp.data.data.detail.forEach(row => {
                    values[`${row.jenis_kerusakan_id}.kondisi`] = row.kondisi
                    values[`${row.jenis_kerusakan_id}.keterangan`] = row.keterangan

                    totalValue += row.kondisi ? parseInt(row.kondisi) : parseInt(0)
                });
                const totalKondisi = (totalValue / totalData).toFixed(4)
                const statusKondisi = this.getStatusKondisi(totalKondisi)

                values.total_kondisi = totalKondisi
                values.status_kondisi = statusKondisi


                this.setState({
                    loading: false,
                    values: {
                        ...values,
                    },
                });
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    fetchTable = (isNew = false) => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: isNew ? [] : [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        _setAxios("gedung/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            } else {
                this.setState({ loading: false });
            }
        })
    };

    getStatusKondisi = (totals) => {
        let statusKondisi = ""
        if (totals <= 25) {
            statusKondisi = "Baik"
        } else if (totals >= 26 && totals <= 50) {
            statusKondisi = "Rusak Ringan"
        } else if (totals >= 51 && totals <= 75) {
            statusKondisi = "Rusak Sedang"
        } else if (totals >= 76 && totals <= 100) {
            statusKondisi = "Rusak Berat"
        }

        return statusKondisi
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        if (name.includes(".")) {
            let valueNew = value
            if (value < 0) {
                valueNew = 0
            } else if (value > 100) {
                valueNew = 100
            }
            this.setTotal(values, name, valueNew)

            this.setForm(values, name, valueNew)
        } else {
            this.setForm(values, name, value)
        }
    };

    setTotal = (values, name, value) => {
        const nameAr = name.split(".");
        if (nameAr.length >= 2) {
            let totalValue = 0
            const totalData = this.state.ddl.jenis_kerusakan.length > 0 ? this.state.ddl.jenis_kerusakan.length : 0
            for (var key in values) {
                if (!key.includes(".kondisi")) { continue };
                if (name === key) { continue };

                totalValue += values[key] ? parseInt(values[key]) : parseInt(0)
            }
            totalValue += value ? parseInt(value) : parseInt(0)


            const totalKondisi = (totalValue / totalData).toFixed(4)
            const statusKondisi = this.getStatusKondisi(totalKondisi)

            this.setForm(values, "total_kondisi", totalKondisi)
            this.setForm(values, "status_kondisi", statusKondisi)

        }
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        this.setForm(values, name, value)
    };

    setForm = (values, name, value, row = {}) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },

        });
    };

    hideModalCreate = () => {
        this.resetForm()
        this.setState({
            create: {
                visible: false,
                loading: false,
            },

        });
    };

    createSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const validation = this.setValidate(values)
        if (validation !== undefined) {
            return
        }

        const jenisKerusakan = []
        const jenisKerusakanIDs = []

        for (var key in values) {
            if (!key.includes(".")) { continue };

            const keyAr = key.split(".");
            if (keyAr.length >= 2) {
                const id = keyAr[0]
                const name = keyAr[1]

                if (jenisKerusakanIDs.includes(id)) {
                    for (let i = 0; i < jenisKerusakan.length; i++) {
                        if (jenisKerusakan[i].jenis_kerusakan_id === id) {
                            if (name === "kondisi") {
                                jenisKerusakan[i].kondisi = values[key] ? values[key] : 0
                            }
                            if (name === "keterangan") {
                                jenisKerusakan[i].keterangan = values[key] ? values[key] : ""
                            }
                        }
                    }
                } else {
                    jenisKerusakanIDs.push(id)

                    jenisKerusakan.push({
                        jenis_kerusakan_id: id,
                        kondisi: name === "kondisi" ? (values[key] ? values[key] : 0) : 0,
                        keterangan: name === "keterangan" ? (values[key] ? values[key] : "") : "",
                    })
                }

            }
        }

        let thn_dibangun = ""
        if (values.thn_dibangun instanceof Date) {
            thn_dibangun = values.thn_dibangun.getFullYear()
        }
        var params = {
            master: {
                lahan_id: values.lahan_id ? values.lahan_id.id : null,
                nama: values.nama ? values.nama : null,
                jml_lantai: values.jml_lantai ? values.jml_lantai : null,
                kepemilikan: values.kepemilikan ? values.kepemilikan.value : null,
                thn_dibangun: thn_dibangun,
                ukuran_panjang: values.ukuran_panjang ? values.ukuran_panjang : null,
                ukuran_lebar: values.ukuran_lebar ? values.ukuran_lebar : null,
            },
            detail: jenisKerusakan
        }


        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });
        _setAxios("gedung", "POST", params).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                let isNew = true
                this.fetchTable(isNew)
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: {
                ...row,
                lahan_id: {
                    nama: row.lahan_nama,
                    id: row.lahan_id,
                },
                kepemilikan: {
                    label: row.kepemilikan,
                    value: row.kepemilikan,
                }
            }
        });

        this.getGedungByID(row.id)
    };

    hideModalUpdate = () => {
        this.resetForm()
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
    };

    updateSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const validation = this.setValidate(values)
        if (validation !== undefined) {
            return
        }

        const jenisKerusakan = []
        const jenisKerusakanIDs = []

        for (var key in values) {
            if (!key.includes(".")) { continue };

            const keyAr = key.split(".");
            if (keyAr.length >= 2) {
                const id = keyAr[0]
                const name = keyAr[1]

                if (jenisKerusakanIDs.includes(id)) {
                    for (let i = 0; i < jenisKerusakan.length; i++) {
                        if (jenisKerusakan[i].jenis_kerusakan_id === id) {
                            if (name === "kondisi") {
                                jenisKerusakan[i].kondisi = values[key] ? values[key] : 0
                            }
                            if (name === "keterangan") {
                                jenisKerusakan[i].keterangan = values[key] ? values[key] : ""
                            }
                        }
                    }
                } else {
                    jenisKerusakanIDs.push(id)

                    jenisKerusakan.push({
                        jenis_kerusakan_id: id,
                        kondisi: name === "kondisi" ? (values[key] ? values[key] : 0) : 0,
                        keterangan: name === "keterangan" ? (values[key] ? values[key] : "") : "",
                    })
                }

            }
        }

        let thn_dibangun = values.thn_dibangun
        if (values.thn_dibangun instanceof Date) {
            thn_dibangun = values.thn_dibangun.getFullYear()
        }
        var params = {
            master: {
                lahan_id: values.lahan_id ? values.lahan_id.id : null,
                nama: values.nama ? values.nama : null,
                jml_lantai: values.jml_lantai ? values.jml_lantai : null,
                kepemilikan: values.kepemilikan ? values.kepemilikan.value : null,
                thn_dibangun: thn_dibangun,
                ukuran_panjang: values.ukuran_panjang ? values.ukuran_panjang : null,
                ukuran_lebar: values.ukuran_lebar ? values.ukuran_lebar : null,
            },
            detail: jenisKerusakan
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("gedung/" + this.state.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })
    };
    // UPDATE END ------------------------------------------

    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>
                Setiap data Gedung yang dihapus di sistem ini, maka juga akan otomatis menghapus :
                <ul>
                    <li>Data Ruangan yang ada di dalam Gedung</li>
                    <li>Relasi antara Ruangan dengan Rombel, sehingga Rombel tidak memiliki ruang kelas</li>
                </ul>
                ✅ Apakah anda yakin?
            </>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("gedung/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/sarana-prasarana/gedung"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { data, pagination, loading, values, errors, tableOption, ddl } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="id">Tanggal Pembuatan</Radio>
                    <Radio value="nama">Nama</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Gedung"
                        size="small"
                        // placeholder="Nama gedung"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={ddl.lahan}
                        value={values.lahan_id || null}
                        getOptionLabel={(option) =>
                            option.nama
                        }
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("lahan_id", newValue ? newValue : null)
                        }}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Lahan"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="lahan_id"
                            type="text"
                            helperText={
                                this.hasError('lahan_id') ? errors.lahan_id[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={16}>
                    <Autocomplete
                        options={kepemilikanOptions}
                        value={values.kepemilikan || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("kepemilikan", newValue)
                        }}
                        name="kepemilikan"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kepemilikan"
                            size="small"
                            error={this.hasError('kepemilikan')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('kepemilikan') ? errors.kepemilikan[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year']}
                            label="Tahun Dibangun"
                            value={values.thn_dibangun || null}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("thn_dibangun", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('thn_dibangun')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('thn_dibangun') ? errors.thn_dibangun[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>

                </Col>
                <Col xs={24} sm={8}>
                    <TextField
                        fullWidth
                        label="Jumlah Lantai"
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jml_lantai"
                        onChange={this.handleChange}
                        value={values.jml_lantai || ''}
                        error={this.hasError('jml_lantai')}
                        helperText={
                            this.hasError('jml_lantai') ? errors.jml_lantai[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <TextField
                        fullWidth
                        label="Ukuran Panjang (m)"
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="ukuran_panjang"
                        onChange={this.handleChange}
                        value={values.ukuran_panjang || ''}
                        error={this.hasError('ukuran_panjang')}
                        helperText={
                            this.hasError('ukuran_panjang') ? errors.ukuran_panjang[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <TextField
                        fullWidth
                        label="Ukuran Lebar (m)"
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="ukuran_lebar"
                        onChange={this.handleChange}
                        value={values.ukuran_lebar || ''}
                        error={this.hasError('ukuran_lebar')}
                        helperText={
                            this.hasError('ukuran_lebar') ? errors.ukuran_lebar[0] : null
                        }
                    />
                </Col>
            </Row>
        </>

        const formInputKondisiKerusakan = <>
            <Row gutter={[24, 24]}>
                <Col xs={8} style={{ fontWeight: "bold" }}>
                    Jenis Kerusakan
                </Col>
                <Col xs={6} style={{ fontWeight: "bold" }}>
                    Kondisi Kerusakan
                </Col>
                <Col xs={10} style={{ fontWeight: "bold" }}>
                    Keterangan Kerusakan
                </Col>
                {
                    ddl.jenis_kerusakan.map((row) => <>
                        <Col xs={8}>
                            {row.nama}
                        </Col>
                        <Col xs={6}>
                            <TextField
                                fullWidth
                                label="optional"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name={row.id + ".kondisi"}
                                type="number"
                                onChange={this.handleChange}
                                value={values[row.id + ".kondisi"] || ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    min: 0, max: 100
                                }}
                            />
                        </Col>
                        <Col xs={10}>
                            <TextField
                                fullWidth
                                label="optional"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name={row.id + ".keterangan"}
                                type="text"
                                onChange={this.handleChange}
                                value={values[row.id + ".keterangan"] || ''}
                            />
                        </Col>
                    </>)
                }
                <Col xs={8} style={{ fontWeight: "bold" }}>
                    Total Kerusakan
                </Col>
                <Col xs={6}>
                    <TextField
                        fullWidth
                        label="Total"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name={"total_kondisi"}
                        type="number"
                        onChange={this.handleChange}
                        value={values.total_kondisi || ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            readOnly: true,
                            min: 0, max: 100
                        }}
                    />
                </Col>
                <Col xs={10}>
                    <TextField
                        fullWidth
                        label="Kondisi"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name={"status_kondisi"}
                        type="text"
                        onChange={this.handleChange}
                        value={values.status_kondisi || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
            </Row>
        </>

        const modalCreate = <Modal
            title="Form Gedung"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            width={800}
            style={{ top: 20 }}
            keyboard={false}
            maskClosable={false}
            footer={<>
                <Button onClick={this.hideModalCreate} disabled={this.state.create.loading}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >

            <form autoComplete="off" onSubmit={this.createSubmit}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Data Gedung" key="1">
                        {formInput}
                    </TabPane>
                    <TabPane tab="Kondisi Kerusakan" key="2">
                        {formInputKondisiKerusakan}
                    </TabPane>
                </Tabs>
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Gedung"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            width={800}
            style={{ top: 20 }}
            footer={<>
                <Button onClick={this.hideModalUpdate} disabled={this.state.update.loading}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmit}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Data Gedung" key="1">
                        {formInput}
                    </TabPane>
                    <TabPane tab="Kondisi Kerusakan" key="2">
                        {formInputKondisiKerusakan}
                    </TabPane>
                </Tabs>
            </form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item>Gedung</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <Tooltip title="Tambah">
                        <Button type='primary' block onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
                    </Tooltip> : ""}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Gedung"
                        subTitle="Data sarana prasarana gedung"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={this.handleTableSearch}
                                value={this.state.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Lahan',
                                        dataIndex: 'lahan_nama',
                                        width: '12%',
                                    },
                                    {
                                        title: 'Gedung',
                                        dataIndex: 'nama',
                                        width: '20%',
                                    },
                                    {
                                        title: 'Kepemilikan',
                                        dataIndex: 'kepemilikan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Tahun dibangun',
                                        dataIndex: 'thn_dibangun',
                                        sorter: false,
                                        align: "right"
                                    },
                                    {
                                        title: 'Jumlah Lantai',
                                        dataIndex: 'jml_lantai',
                                        sorter: false,
                                        align: "right"
                                    },
                                    {
                                        title: 'Luas Gedung',
                                        key: 'operation',
                                        render: (a, row) => row.luas_gedung,
                                        align: "right"
                                    },
                                    {
                                        title: 'Kondisi kerusakan',
                                        sorter: false,
                                        render: (a, row) => (row.total_kerusakan ? row.total_kerusakan : 0) + " %",
                                        align: "right"
                                    },
                                    {
                                        title: 'Kategori Kondisi',
                                        dataIndex: 'kondisi',
                                        sorter: false,
                                        align: "center",
                                        render: (a, row) => <>
                                            {row.kondisi === "Baik" ? <Tag color="#12784A "> {row.kondisi}</Tag> : (row.kondisi === "Rusak Ringan" ? <Tag color="green"> {row.kondisi}</Tag> : (row.kondisi === "Rusak Sedang" ? <Tag color="yellow"> {row.kondisi}</Tag> : (row.kondisi === "Rusak Berat" ? <Tag color="red"> {row.kondisi}</Tag> : "-")))}
                                        </>,
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                            {action.delete ? (<Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={data}
                                pagination={pagination}
                                loading={loading}
                                onChange={this.handleTableChange}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalCreate}

                {modalUpdate}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Gedung);
