import { Divider, Empty } from 'antd';
import React from 'react';
const EmptyDocument = () => (
  <Empty
    description={
      <div>
        <div style={{fontStyle: 'italic', color: 'rgba(0,0,0,0.45)'}}>Tidak ada dokumen aktif</div>
        <Divider/>
        <div style={{fontStyle: 'italic'}}>Silahkan tambahkan dokumen & aktifkan <br/>pada tab struktur organisasi</div>
      </div>
    }
  >
  </Empty>
);
export default EmptyDocument;
