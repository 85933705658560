import React from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { getParamTable } from '../../lib/Helper';
import { isMobile } from 'react-device-detect';

class SearchTable extends React.Component {

  render() {
    const { table } = this.props
    return (
      <Input
        name="search"
        className='search-table'
        placeholder="Search..."
        prefix={<SearchOutlined className="site-form-item-icon" />}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     const params = getParamTable("search", table, e.target.value)
        //     this.props.fetch(params, isMobile ? true : false)
        //   }
        // }}
        onChange={(e) => {
          const params = getParamTable("search", table, e.target.value)
          this.props.fetch(params, isMobile ? true : false)
        }}
        suffix={
          <Tooltip title="Cari berdasarkan nama">
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        }
      />
    )
  }
}

export default SearchTable
