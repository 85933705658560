import React from 'react';
import { connect } from 'react-redux'
import { Layout, Menu, Row, Col, Divider, Typography, Space, Button, Popover } from 'antd';

import './Sidebar.css';
import logoMan from '../../assets/images/logo-man.png';
import { Link } from "react-router-dom";
import { BrowserView } from 'react-device-detect';
import { excludeWidgetPath } from '../../data/constant';

const { Sider } = Layout;
const { Title, Text } = Typography;

class Sidebar extends React.Component {
  state = {
    openKey: 0,
    isPathName: true,
  };

  getItem(name, label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
      name,
    };
  }

  onMenuClick = (key) => {
    this.setState({
      isPathName: true
    })
  }

  render() {
    const { privilege, menuCollapse } = this.props;
    const { isPathName } = this.state;
    const menu = this.props.menu

    let pathname = window.location.pathname;
    const path = pathname.split("/");
    let openKey = this.state.openKey
    if (path.length > 0) {
      openKey = path[1]
    }
    if (this.state.collapsed === true) {
      openKey = 0
    }
    if (pathname === "/" || pathname === "") {
      pathname = "/"
    }
    if (pathname.includes("/create")) {
      pathname = pathname.replace("/create", "");
    }

    const lastSegment = pathname.split("/").pop();
    if (pathname.includes("/pelajaran/detail")) {
      pathname = pathname.replace("/pelajaran/detail/" + lastSegment, "");
    } else if (pathname.includes("/mengajar/detail")) {
      pathname = pathname.replace("/mengajar/detail/" + lastSegment, "");
    } else if (pathname.includes("/pelanggaran-siswa/detail")) {
      pathname = pathname.replace("/pelanggaran-siswa/detail/" + path[4] + "/" + lastSegment, "/pelanggaran-siswa");
    } else if (pathname.includes("/penghargaan-siswa/detail")) {
      pathname = pathname.replace("/penghargaan-siswa/detail/" + path[4] + "/" + lastSegment, "/penghargaan-siswa");
    }

    if (pathname.includes("/detail")) {
      pathname = pathname.replace("/detail/" + lastSegment, "");
    }

    if (!isPathName){
      pathname = "404"
      openKey = 0
    }

    const items = []
    const itemsMenu = []
    for (let i = 0; i < menu.length; i++) {
      const subItems = []
      if (menu[i].children) {
        if (menu[i].children.length > 0) {
          for (let j = 0; j < menu[i].children.length; j++) {
            const subMenu = menu[i].children[j]
            subItems.push(this.getItem(subMenu.name, <Link to={subMenu.path}>{subMenu.name}</Link>, (subMenu.path ? subMenu.path : i), <span className="icon-sidebar icon-circle" style={{ fontSize: "60%" }}></span>))
          }
        }
      }

      let linkPath = ""
      if (menu[i].path.includes("drive.google.com")) {
        linkPath = <a href={menu[i].path} target="_blank" rel="noopener noreferrer">{menu[i].name}</a>
      } else {
        linkPath = <Link to={menu[i].path}>{menu[i].name}</Link>
      }

      if (menu[i].children && !excludeWidgetPath.includes(menu[i].path)) {
        items.push(this.getItem(menu[i].name, linkPath, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
      }
      if (excludeWidgetPath.includes(menu[i].path) || menu[i].path.includes("drive.google.com")) {
        itemsMenu.push(this.getItem(menu[i].name, linkPath, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
      }
    }

    let styleProfile = {
      margin: "-54px auto 0px",
      width: "fit-content"
    }
    let styleProfileImage = {
      backgroundImage: `url("` + logoMan + `")`,
      backgroundColor: "rgb(255, 255, 255)",
      padding: "8px",
      borderRadius: "100%",
      width: "80px",
      overflow: "hidden",
      height: "80px",
      backgroundSize: "56px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      border: "3px solid rgb(224 224 224 / 50%)"
    }
    if (menuCollapse) {
      styleProfile = {
        margin: "24px auto 0px",
        width: "fit-content"
      }
      styleProfileImage = {
        backgroundImage: `url("` + logoMan + `")`,
        backgroundColor: "rgb(255, 255, 255)",
        padding: "8px",
        borderRadius: "100%",
        width: "40px",
        overflow: "hidden",
        height: "40px",
        backgroundSize: "36px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        border: "3px solid rgb(224 224 224 / 50%)"
      }
    }

    return (
      <BrowserView>
        <Sider collapsed={menuCollapse} width={270} style={{
          height: "100%",
          marginTop: -8
        }}>
          <Row gutter={[16, 16]}>
            {
              menuCollapse ? "" :
                <Col xs={24}>
                  <div className='sidebar-profile'>
                    <div className='sidebar-grid-container'>
                    </div>
                  </div>
                </Col>
            }
            <Col xs={24}>
              <div style={styleProfile}>
                <div style={styleProfileImage}>
                </div>
              </div>
            </Col>
            <Col xs={24} style={{
              textAlign: "center"
            }}>
              <div style={menuCollapse ? {
                fontSize: '50%'
              } : {
                fontWeight: "bold"
              }}>{privilege.profile.name}</div>
              <div style={menuCollapse ? {
                fontSize: '50%',
                color: "#8C8C8C"
              } : {
                color: "#8C8C8C"
              }}>{privilege.profile.roles}</div>
              <Divider style={{ marginBottom: 8 }} />
              {menuCollapse ? <Space>
                <Title level={5}><Text style={{ fontSize: "50%" }} type="secondary">Semester {privilege.tahun_ajaran.tipe_semester}</Text><div style={{fontSize: "50%" }}>{privilege.tahun_ajaran.thn_awal + " - " + privilege.tahun_ajaran.thn_akhir}</div></Title>
              </Space> : <Space>
                <Title level={5}><Text type="secondary">Semester {privilege.tahun_ajaran.tipe_semester}</Text> | {privilege.tahun_ajaran.thn_awal + " - " + privilege.tahun_ajaran.thn_akhir}</Title>
              </Space>}
              <Divider style={{ marginTop: 4 }} />
            </Col>
          </Row>
          {
            menuCollapse ? "" : <div className='container-widget'>
            {
              items.map((item) => {
                return <Popover
                  className='item-widget'
                  placement='bottomRight'
                  title={item.name}
                  content={<div>
                    {
                      item.children.map((subItem) => {
                        return <div key={subItem.key} className='link-widget-content'><Link to={subItem.key} title={subItem.name} className='link-widget'>{subItem.icon} {subItem.name}</Link></div>
                      })
                    }
                  </div>}>
                  <Button title={item.name} type="primary" icon={item.icon} />
                </Popover>
              })
            }
          </div>
          }
          <Menu
            defaultSelectedKeys={[pathname, `#${pathname}`]}
            defaultOpenKeys={[openKey, `#${openKey}`]}
            onClick={this.onMenuClick}
            mode="inline"
            items={menuCollapse ? [...items, ...itemsMenu] : itemsMenu} />
          <br />
        </Sider>
      </BrowserView>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
    menu: state.menu,
    menuCollapse: state.menuCollapse,
    isMobile: state.isMobile,
  }
}

export default connect(mapStateToProps)(Sidebar);
