import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { DownCircleOutlined, EditOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LabelIsActive, LabelIsUsed } from '../../../../../../components/labels';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                    title={row.tipe + " - " + row.periode}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Penggunaan: <LabelIsUsed isUsed={row.is_used === "1" ? true : false} /><br />
                              Status: <LabelIsActive isActive={row.is_active === "1" ? true : false} /><br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
