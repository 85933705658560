import React from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const LabelIsActive = ({ ...props }) => {

  if(props.isActive) {
    return (
      <Tag color="green" icon={<CheckCircleOutlined />}> Aktif</Tag>
    );
  }
  
  return (
    <Tag color="red" icon={<CloseCircleOutlined />}>Non-Aktif</Tag>
  );
};
export default LabelIsActive;
