import React from 'react';
import { DatePicker } from 'antd';
import { yearFormat } from '../../constant/constant_format';

const { RangePicker } = DatePicker;


const RangeYear = ({ ...props }) => {
    return (
        <RangePicker 
        {...props}
        picker="year" 
        style={props.style ? props.style : { width: '100%' }}
        format={yearFormat} 
        />
    );
};
export default RangeYear;
