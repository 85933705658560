import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, PageHeader, BackTop, Breadcrumb, List } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import { settingService } from './setting.service';


class Setting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            settings : [],
        }
    }

    componentDidMount() {
        settingService().getSettings().then(body => {
            this.setState({settings: body.list})
        })
    }

    render() {
        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Setting</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Setting"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.settings}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={item.setting_name}
                                            description={item.setting_val}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </div>

                <BackTop />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Setting);
