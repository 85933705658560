import React from 'react';
import { Modal, Button, Form } from 'antd';
import SelectDropdown from '../../../../components/select/SelectDropdown';

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    render() {

        const { data } = this.props

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Dropdown">
                        <SelectDropdown
                            defaultValue={data.modul_id}
                            value={data.modul_id}
                            onChange={(val) => {
                                this.props.setTempTableFilter("modul_id", val ? val : null)
                            }}
                        />
                    </Form.Item>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
