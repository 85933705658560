import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _success, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../lib/Helper';
import validate from 'validate.js';
import AuthRedirect from '../../../components/AuthRedirect'
import moment from 'moment';

import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Input, Tooltip, Modal, Select, Form, Breadcrumb, DatePicker, Dropdown, Menu } from 'antd';
import { InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, FilterOutlined, DoubleRightOutlined, DownloadOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { tipeLaporanOptions } from '../../../data/options';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'DD-MM-YYYY';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}
const schema = {}

class Laporan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Siswa"
                },
            },
            filter: {
                visible: false,
                values: {},
            },
            export: {
                loading: false,
                visible: false,
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("konsultasi/table-laporan", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------


    // EXPORT START ------------------------------------------

    hideModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: false,
            },
        });
    };

    showModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: true
            },
        });
    };

    onExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                loading: true,
            },
        });
        _setAxios("konsultasi/export-laporan", "POST", {
            tipe: this.state.values.tipe_export,
            tgl_awal: this.state.values.tgl_awal_export,
            tgl_akhir: this.state.values.tgl_akhir_export,
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            }

            this.setState({
                export: {
                    ...this.state.export,
                    loading: false,
                },
            });
        })
    }

    // EXPORT END ------------------------------------------

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/laporan"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        const { table } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Laporan"
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tipe">
                    <Select
                        showSearch
                        allowClear
                        name="tipe"
                        placeholder="Tipe"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("tipe", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {tipeLaporanOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Periode">
                    <RangePicker
                        style={{
                            width: "100%", borderRadius: 4, padding: "6px 10px"
                        }}
                        format={dateFormat}
                        onChange={val => {

                            if (val) {
                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)

                                this.setTempTableFilter("tgl_awal", periodeAwal)
                                this.setTempTableFilter("tgl_akhir", periodeAkhir)
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>

        const modalExport = <Modal
            title="Export Laporan"
            visible={this.state.export.visible}
            onCancel={this.hideModalExport}
            footer={<>
                <Button onClick={this.hideModalExport}>Tutup</Button>
                <Button type='primary' onClick={this.onExport}>Export</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tipe">
                    <Select
                        showSearch
                        allowClear
                        name="tipe"
                        placeholder="Tipe"
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("tipe_export", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {tipeLaporanOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Periode">
                    <RangePicker
                        style={{
                            width: "100%", borderRadius: 4, padding: "6px 10px"
                        }}
                        format={dateFormat}
                        onChange={val => {

                            if (val) {
                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)

                                this.handleChangeSetValue("tgl_awal_export", periodeAwal)
                                this.handleChangeSetValue("tgl_akhir_export", periodeAkhir)
                            }else{
                                this.handleChangeSetValue("tgl_awal_export", null)
                                this.handleChangeSetValue("tgl_akhir_export", null)
                            }
                        }}
                    />
                </Form.Item>
                <div style={{ textAlign: "left" }}>Log export <Link to={`/log/export`}>klik disini</Link></div>

            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Laporan Konseling</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={[
                        action.export ? <Button onClick={this.showModalExport}><DownloadOutlined /> Export</Button> : null
                    ]}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Laporan Konseling"
                        subTitle="Data laporan konseling"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Nama Rombel',
                                        dataIndex: 'rombel_nama',
                                    },
                                    {
                                        title: 'Pribadi',
                                        dataIndex: 'pribadi',
                                    },
                                    {
                                        title: 'Sosial',
                                        dataIndex: 'sosial',
                                    },
                                    {
                                        title: 'Belajar',
                                        dataIndex: 'belajar',
                                    },
                                    {
                                        title: 'Karir',
                                        dataIndex: 'karir',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                          {action.detail === true ? (<Menu.Item key={0}><Link to={"/bimbingan-konseling/laporan/detail/" + row.rombel_id + "?tipe=" + this.state.table.filtering.tipe + "&tgl_awal=" + this.state.table.filtering.tgl_awal + "&tgl_akhir=" + this.state.table.filtering.tgl_akhir}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                        </Menu>}>
                                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                      },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalFilter}
                {modalExport}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Laporan);
