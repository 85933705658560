import React from 'react';
import { Modal, Button, Form, Select } from 'antd';
import { jenisPenegakOptions } from '../../../../../data/options';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    
                    <Form.Item label="Jenis Penegak">
                        <Select
                            allowClear
                            name="jenis_penegak"
                            placeholder="Jenis Penegak"
                            onChange={(e, newValue) => {
                                this.props.setTempTableFilter("jenis_penegak", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {jenisPenegakOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
