import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

async function fetchList() {

  // return await _setAxios("setting/dropdown/modul/40")
  //   .then(body => {
  //     let results = []
  //     body.data.data.forEach(row => {
  //       results.push({
  //         label: row.list,
  //         value: row.id,
  //       })
  //     });

  //     return results
  //   })

  return new Promise((resolve) => {
    resolve([
      { label: 'Raudhatul Athfal', value: 'Raudhatul Athfal' },
      { label: 'Madrasah Ibtidaiyah', value: 'Madrasah Ibtidaiyah' },
      { label: 'Madrasah Tsanawiyah', value: 'Madrasah Tsanawiyah' },
      { label: 'Madrasah Aliyah', value: 'Madrasah Aliyah' },
  ]);
  });

}

const SelectJenisLembaga = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  if (!options.length) return "Loading..."

  return (
    <Autocomplete
      {...props}
      value={options.find(option => option.value === props.value)}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => <TextField {...params}
        fullWidth
        label="Jenis lembaga"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        name="jenis"
        type="text"
        helperText={
          props.errors.jenis ? props.errors.jenis[0] : null
        }
        error={
          props.errors.jenis
        }
      />}
    />
  );
};
export default SelectJenisLembaga;
